import React, { useEffect, useState } from "react";
import { Table, Modal, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction } from "../../Dashboard/State/actions";
import { useNavigate, useParams } from "react-router-dom";
import { captainAction } from "../State/actions";
import useWarnings from "../../Common/Components/useWarnings";
import useCaptainColumns from "./useCaptainColumns";
const { TextArea } = Input;

/**
 * @param - no params needed
 * This component displays a table of bookings for a specific captain, allowing
 * actions such as accepting or rejecting bookings and canceling the booking with a reason.
 */

const CaptainsBookings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBooking, setCurrentBooking] = useState(null);
  const dispatch = useDispatch();
  const { otherWarning, contextHolder } = useWarnings();
  const { captainUniqueId } = useParams();
  const { getCaptainsBookings, totalCaptainsBookings } = useSelector(
    (str) => str.dashboardReducer
  );
  const { isLoading } = useSelector((str) => str.loadingReducer);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(dashboardAction.getCaptainsBookings(captainUniqueId, 1));
  }, [dispatch]);

  const showRejectModal = (booking, value) => {
    setCurrentBooking({
      ...booking,
      type: value,
    });
    setIsModalVisible(true);
  };

  // when admin accepts decline request of captains's booking
  const handleAccept = (booking) => {
    dispatch(
      dashboardAction.acceptOrRejectCaptainsDeclineRequest(
        booking.captainUniqueId,
        booking.bookingUniqueId,
        "accept"
      )
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentBooking(null);
  };

  // when admin rejects decline request of captains's booking
  const handleReject = () => {
    setIsModalVisible(false);
    dispatch(
      dashboardAction.acceptOrRejectCaptainsDeclineRequest(
        captainUniqueId,
        currentBooking.bookingUniqueId,
        "decline"
      )
    );
  };

  const handleCancelCaptainBooking = () => {
    if (!currentBooking.reasonForRejection) {
      return otherWarning();
    }
    const data = {
      captainId: currentBooking.captainId,
      bookingId: currentBooking.bookingId,
      reason: currentBooking.reasonForRejection,
    };
    dispatch(
      captainAction.cancelCaptainBookingEvent(
        captainUniqueId,
        currentBooking.bookingUniqueId,
        data
      )
    );
    setIsModalVisible(false);
    navigate(`/captains/${currentBooking.bookingUniqueId}`);
  };

  const loadMoreData = (page) => {
    if (!isLoading && getCaptainsBookings?.length < totalCaptainsBookings)
      dispatch(dashboardAction.getCaptainsBookings(captainUniqueId, page));
  };

  const { columns } = useCaptainColumns({
    handleAccept,
    showRejectModal,
  });

  return (
    <div>
      {contextHolder}
      {/* <DriverDetails getCaptainsBookings={getCaptainsBookings} /> */}
      <Table
        columns={columns}
        dataSource={getCaptainsBookings}
        pagination={false}
        loading={isLoading}
        bordered
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            if (!isLoading) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage);
            }
          }
        }}
        scroll={{
          y: 500,
        }}
      />
      <Modal
        title="Reason"
        open={isModalVisible}
        onCancel={handleCancel}
        onOk={() =>
          currentBooking.type === "declineType"
            ? handleReject()
            : handleCancelCaptainBooking()
        }
        okText={"Reassign"}
      >
        <TextArea
          rows={4}
          value={currentBooking ? currentBooking.reasonForRejection : ""}
          onChange={(e) =>
            setCurrentBooking({
              ...currentBooking,
              reasonForRejection: e.target.value,
            })
          }
        />
      </Modal>
    </div>
  );
};

export default CaptainsBookings;
