import { startLoading, stopLoading } from "../../state/actions";
import { startLoadingAllUsers, stopLoadingAllUsers } from "../../state/additionalLoadingActions";
import { usersApi } from "../utils/api";
import { userConstants } from "./action-type";

const getUsersSuccess = (data) => {
  return {
    type: userConstants.GET_USERS_SUCCESS,
    data: data,
  };
};

const getUsersErr = (err) => {
  return {
    type: userConstants.GET_USERS_ERROR,
    valuesError: err,
  };
};

const getUsersEvent = (page, search, sort) => {
  return (dispatch) => {
    dispatch(startLoadingAllUsers());
    usersApi
      .getAllUsers(page, search, sort)
      .then((response) => {
        dispatch(getUsersSuccess(response.data.info));
        dispatch(stopLoadingAllUsers());
      })
      .catch((err) => {
        dispatch(getUsersErr(err?.response?.data?.message));
        dispatch(stopLoadingAllUsers());
      });
  };
};

export const usersAction = {
  getUsersEvent,
};
