import React, { useEffect, useState } from "react";
import { Button, Col, Row, Upload, Card, Typography, Form, theme, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import PetInputFields from "./PetInputFields";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../State/actions";
import { ImageUpload } from "./ImageUpload";
import CommonModal from "../../CommonModal/CommonModal";
const { Text } = Typography;
/**
 * @description
 * The `PetDetails` component is a form for capturing and editing pet details. The form is prefilled
 * with existing pet details if available, and includes validation for required fields.
 */
const PetDetails = ({
  normFile,
  handleFinish,
  petDetails,
  boardingCenterallowedPets,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isLoadingPostUpdatePetDetails } = useSelector(
    (str) => str.additionalloadingReducer
  );
  const [cert, setCert] = useState("");
  const [visible, setVisible] = useState(false);
  console.log(cert, "cert");

  useEffect(() => {
    if (petDetails && Object.keys(petDetails).length > 0) {
      form.setFieldsValue(petDetails?.[0] || petDetails);
      setCert(
        petDetails?.[0]?.vaccineCertificate || petDetails?.vaccineCertificate
      );
    }
  }, [petDetails]);

  useEffect(() => {
    return () => {
      dispatch(profileAction.cleardetailsProfile());
    };
  }, []);

  return (
    <>
      <Card hoverable style={{ marginTop: "1.2em" }}>
        <Typography.Title
          level={2}
          style={{ marginTop: 0, color: token.colorPrimary }}
        >
          Pet Details
        </Typography.Title>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Row gutter={[16, 0]} style={{ marginTop: "30px" }}>
            {/* inputs like name, sex, age, fav foods,type,breed */}
            <PetInputFields
              boardingCenterallowedPets={boardingCenterallowedPets}
              Form={Form}
              form={form}
            />
            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
              <Form.Item
                name={"vaccineCertificate"}
                valuePropName="vaccineCertificate"
                getValueFromEvent={normFile}
                label="Upload Vaccine Certificate"
                extra={<>
                  {/* <Checkbox>Need vaccine certificate</Checkbox> */}
                  <Text style={{ color: "grey" }}>*only .pdf are allowed</Text>
                </>}
              >
                <ImageUpload
                  origin="uploadPdf"
                  form={form}
                  setCert={setCert}
                  maxCount={1}
                />
                {cert && (
                  <Button
                    size="small"
                    type="link"
                    onClick={() => setVisible(true)}
                  >
                    View certificate
                  </Button>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                loading={isLoadingPostUpdatePetDetails}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
        <CommonModal
          isModalOpen={visible}
          body={() => (
            <iframe
              src={cert}
              width="100%"
              height="600px"
              title="Vaccine Certificate"
            />
          )}
          handleCancel={() => setVisible(false)}
        />
      </Card>
    </>
  );
};

export default PetDetails;
