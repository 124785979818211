import React, { useEffect, useState } from "react";
import { certificateApi } from "./utils/api";
import useWarnings from "../Common/Components/useWarnings";
import { useRoute } from "../context/RouteContext";
import dayjs from "dayjs";
import { Button, Table, Typography, Modal } from "antd";
import CommonModal from "../CommonModal/CommonModal";

const { Text } = Typography;

const VaccineCertificates = () => {
    const [certificates, setCertificates] = useState([]);
    const [loading, setLoading] = useState(false);
    const { success, error, contextHolder } = useWarnings();
    const { setSelectedKey } = useRoute();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const getCertificates = async () => {
        setLoading(true);
        try {
            const response = await certificateApi.getAllCertificates();
            if (response?.data?.info) {
                setCertificates(response.data.info);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            error(err?.response?.data?.message);
        }
    };

    const handleApprove = (id, data) => {
        try {
            certificateApi.updateCertificate(data, id).then(res => {
                setLoading(false);
                if (res.data.info) {
                    getCertificates();
                    success("Updated successfully")
                }
            });
        } catch (err) {
            setLoading(false);
            error(err?.response?.data?.message);
        }
    }

    useEffect(() => {
        getCertificates();
        setSelectedKey("GET_UNVERIFIED_VACCINE_CERTIFICATES");
    }, []);

    const showPdfModal = (certificateUrl) => {
        setPdfUrl(certificateUrl);
        setIsModalVisible(true);
    };

    const columns = [
        {
            title: "Created on",
            key: "createdAt",
            render: (_, { createdAt }) => (
                <Text>{dayjs(createdAt).format("MMM D, YYYY h:mm A")}</Text>
            ),
        },
        {
            title: "Pet Details",
            key: "petDetails",
            render: (_, { pet }) => (
                <>
                    <Text style={{ display: "block" }}>{pet.name}</Text>
                    <Text style={{ display: "block" }}>{pet.type}</Text>
                    <Text style={{ display: "block" }}>{pet.breed}</Text>
                </>
            ),
        },
        {
            title: "View",
            key: "view",
            render: (_, { pet }) => (
                <Button
                    type="link"
                    onClick={() => showPdfModal(pet?.vaccineCertificate)}
                >
                    View Certificate
                </Button>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, { pet }) => (
                <>
                    <Button size="small" onClick={() => handleApprove(pet.petUniqueId, { isVerified: true })}>Approve</Button>
                    <Button size="small" onClick={() => handleApprove(pet.petUniqueId, { isVerified: false })}>Reject</Button>
                </>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Table
                columns={columns}
                pagination={false}
                bordered
                dataSource={certificates}
                loading={loading}
            />

            <CommonModal
                title="Vaccine Certificate"
                width={800}
                isModalOpen={isModalVisible}
                body={() =>
                    pdfUrl ? (
                        <iframe
                            src={pdfUrl}
                            width="100%"
                            height="600px"
                            title="Vaccine Certificate"
                        />
                    ) : (
                        <p>No certificate available</p>
                    )
                }
                handleCancel={() => setIsModalVisible(false)}
            />
        </>
    );
};

export { VaccineCertificates };
