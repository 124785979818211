import React, { useEffect, useState } from "react";
import ParentDetails from "../../Common/Components/ParentDetails";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PetDetails from "../../Common/Components/PetDetails";
import Subscriptions from "./Subscriptions";
import Addresses from "./Addresses";
import { profileAction } from "../../Common/State/actions";
import { Tabs } from "antd";
import { settingsAction } from "../State/actions";
import TicketsForUser from "./TicketsForUser";
import useSettingName from "../../Common/Components/useSettingName";
import useWarnings from "../../Common/Components/useWarnings";
import Passwords from "./Passwords";
/**
 * @description
 * The `SettingsItems` component provides the configuration for the tabs used in the `Settings` component. It returns an array of tab items, each representing a section of the settings page. The tabs include sections for parent profile, pet profile, subscription plans, addresses, orders, and support tickets. The component handles updating parent and pet profiles, as well as managing the state and actions for each tab's content.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.boardingCenterallowedPets - Array of allowed pets for the boarding center.
 * @param {Function} props.showDrawer - Function to toggle the drawer visibility.
 * @param {string} props.activekeyInOrders - The currently active tab key in the orders section.
 * @param {Function} props.setActivekeyInOrders - Function to set the active key in the orders tab.
 * @param {Function} props.setPage - Function to set the current page number for pagination.
 * @param {Array} props.getItems - Array of tab items for the orders section.
 *
 * @returns {Array} An array of tab objects for the Ant Design Tabs component.
 *
 * @methods
 * - `onFinish(values)`: Called when the parent details form is submitted. Dispatches an action to update the parent profile.
 * - `handleFinish(values)`: Called when the pet details form is submitted. Dispatches an action to update or create the pet profile.
 */

const SettingsItems = ({
  boardingCenterallowedPets,
  showDrawer,
  activekeyInOrders,
  setActivekeyInOrders,
  setPage,
  getItems,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    parentDetails,
    petDetails,
    updatedProfileDetails,
    updatedProfileDetailsErr,
    updatedPetProfileDetails,
    updatedPetProfileDetailsErr,
  } = useSelector((str) => str.profileReducer);
  const { success, error, contextHolder } = useWarnings();

  const [idType, setIdType] = useState("");
  const [idPattern, setIdPattern] = useState("");

  // called when parent details are saved
  const onFinish = (values) => {
    let changedData = {};

    // Check for changes in each field
    for (const key in values) {
      if (values[key] !== parentDetails[key]) {
        if (key === "phoneNumber") {
          if (values[key] && values[key].trim() !== "") {
            const phoneNumber = values[key];
            const countryCode = phoneNumber.substring(
              0,
              phoneNumber.length - 10
            );
            const formattedPhoneNumber = phoneNumber.substring(
              phoneNumber.length - 10
            );
            changedData["countryCode"] = countryCode;
            changedData["phoneNumber"] = formattedPhoneNumber;
          }
        } else {
          if (key === "profileImage" && values["profileImage"] === null) {
            changedData['removeProfileImage'] = true;
          }
          changedData[key] = values[key];
        }
      }
    }
    dispatch(profileAction.updateParentProfileEvent(changedData));
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList[0]?.originFileObj;
  };

  // called when pet details are saved
  const handleFinish = (values) => {
    if (petDetails[0]?.petUniqueId) {
      const changedFields = {};
      Object.keys(values).forEach((field) => {
        if (values[field] !== petDetails[0][field]) {
          // Add the field to the changedFields object if the values are different or it's a new field
          changedFields[field] = values[field];
        }
      });
      if (Object.keys(changedFields).length > 0)
        dispatch(
          profileAction.updatePetProfileEvent(changedFields, petDetails[0].petUniqueId)
        );
    } else {
      dispatch(profileAction.postPetProfileEvent(values));
    }
  };

  useEffect(() => {
    if (updatedProfileDetails) {
      success("updated successfully!");
      dispatch(profileAction.cleardetailsProfile());
    } else if (updatedProfileDetailsErr) {
      error(updatedProfileDetailsErr);
      dispatch(profileAction.cleardetailsProfile());
    }
    if (updatedPetProfileDetails) {
      dispatch(profileAction.cleardetailsProfile());
      // dispatch(profileAction.getPetProfileEvent());
      success("updated successfully!");
    } else if (updatedPetProfileDetailsErr) {
      error(updatedPetProfileDetailsErr);
      dispatch(profileAction.cleardetailsProfile());
      // dispatch(profileAction.getPetProfileEvent());
    }
  }, [
    updatedProfileDetails,
    updatedProfileDetailsErr,
    updatedPetProfileDetails,
    updatedPetProfileDetailsErr,
  ]);

  return [
    {
      key: "parentProfile",
      label: t("parent_profile"),
      children: (
        <>
          {contextHolder}
          <ParentDetails
            onFinish={onFinish}
            setIdPattern={setIdPattern}
            setIdType={setIdType}
            idType={idType}
            idPattern={idPattern}
            parentDetails={parentDetails}
          />
        </>
      ),
    },
    {
      key: "petProfile",
      label: t("pet_profile"),
      children: (
        <>
          {contextHolder}
          <PetDetails
            boardingCenterallowedPets={boardingCenterallowedPets}
            handleFinish={handleFinish}
            normFile={normFile}
            petDetails={petDetails}
          />
        </>
      ),
    },
    {
      key: "subscriptionPlans",
      label: t("subscriptions"),
      children: <Subscriptions />,
    },
    {
      key: "addresses",
      label: t("addresses"),
      children: <Addresses showDrawer={showDrawer} />,
    },
    {
      key: "orders",
      label: "Orders",
      children: (
        <>
          <Tabs
            centered
            activeKey={activekeyInOrders}
            onChange={(e) => {
              setActivekeyInOrders(e);
              dispatch(settingsAction.clearAllBookings());
              setPage(1);
            }}
            items={getItems}
          />
        </>
      ),
    },
    {
      key: "tickets",
      label: "Support Tickets",
      children: <TicketsForUser />,
    },
    {
      key: "passwords",
      label: "Passwords",
      children: <Passwords />,
    },
  ];
};

export default SettingsItems;
