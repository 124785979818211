import React from "react";
import Spinner from "../../Spinner/Spinner";
import { NotFound } from "../../Common/Components/NotFound";
import { useSelector } from "react-redux";
/**
 * @function useCheckAllDetails
 * @description Custom React hook that checks various conditions related to appointment booking and boarding details.
 * It returns appropriate UI components like a spinner during loading, or a NotFound component with a specific message
 * if there's an error or missing data.
 *
 * @returns {JSX.Element|null} - Returns a JSX element (Spinner or NotFound) based on loading state, errors, or missing data.
 * If all checks pass, it returns null, allowing the parent component to render normally.
 */
const useCheckAllDetails = () => {
  const {
    bookappointmentdetails,
    updateddetailsError,
    bookappointmentdetailsError,
  } = useSelector((str) => str.bookAppointmentReducer);
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);
  const {
    isLoading,
    isLoadingFinalAppointDetails,
    isLoadingParentBoardingCenter,
  } = useSelector((str) => str.loadingReducer);
  if (
    isLoading ||
    isLoadingFinalAppointDetails ||
    isLoadingParentBoardingCenter
  ) {
    return <Spinner />;
  }
  if (updateddetailsError) {
    return (
      <NotFound
        title={updateddetailsError?.message}
        continueTo={"/book-appointment/step-2"}
      />
    );
  }
  if (Object.keys(parentBoardingDetails)?.length === 0) {
    return (
      <NotFound
        title={
          "Services are not available at this pincode. Please choose another location."
        }
        continueTo={"/"}
      />
    );
  }
  if (bookappointmentdetailsError) {
    return (
      <NotFound
        title={bookappointmentdetailsError?.response?.data?.message}
        buttonName="Back"
        continueTo={
          bookappointmentdetailsError?.response?.data?.responseType ===
          "RESERVATION_NOT_FOUND" || "NO_PET_DETAILS_FOUND"
            ? "/book-appointment/step-2"
            : "/"
        }
      />
    );
  }

  if (bookappointmentdetails?.petDetails === null) {
    return (
      <NotFound
        title={"Please Complete pet profile to book your appointment"}
        continueTo={"/accounts/petProfile"}
        buttonName="Go to Profile"
      />
    );
  } else if (
    !bookappointmentdetails ||
    !bookappointmentdetails?.currentParent?.contactNumber?.phoneNumber
  ) {
    return (
      <NotFound
        title={"Please complete your profile"}
        continueTo={"/accounts/parentProfile"}
        buttonName="Go to Profile"
      />
    );
  } else if (
    !bookappointmentdetails ||
    bookappointmentdetails?.bookingDetails?.services.length === 0
  ) {
    return (
      <NotFound
        title={"Please select services to book your appointment"}
        continueTo={"/book-appointment/step-1"}
      />
    );
  }

  return null;
};
export default useCheckAllDetails;
