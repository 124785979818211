
import { Card, Divider, Form, Image, Typography } from "antd";
import React, { useRef } from "react";
import styles from "./Styles/signup.module.css";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import googlesvg from "../Assets/Login/googlesvg.svg";
import { useDispatch } from "react-redux";
import { signupAction } from "./State/actions";
import SignupForm from "./Components/SignupForm";
import useShowMessage from "./Components/useShowMessage";
const { Text } = Typography;

/**
 * Signup component provides a user interface for creating a new account.
 * It includes options to signup via email, along with a form for entering user details.

 * @returns {JSX.Element} The rendered Signup component.
*/

const Signup = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const captchaRef = useRef();

  const handleSignup = (formvalues) => {
    const { cpassword, phoneNumber, ...formData } = formvalues;

    let signupdata = { ...formData };

    if (phoneNumber && phoneNumber.trim() !== "") {
      const countryCode = phoneNumber.substring(0, phoneNumber.length - 10);
      const formattedPhoneNumber = phoneNumber.substring(
        phoneNumber.length - 10
      );
      signupdata = {
        ...signupdata,
        countryCode,
        phoneNumber: formattedPhoneNumber,
      };
    }
    dispatch(signupAction.signupEvent(signupdata));
    // captchaRef.current.reset();
    form.resetFields();
  };

  const { contextHolder } = useShowMessage({captchaRef});

  return (
    <>
      {contextHolder}
      <div className={styles.signuppage} style={{ flexDirection: "column",backgroundImage:`url("/Assets/LoginAssets/loginscreen.png")`  }}>
        {/* dogs image */}
        <div
          style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}
        >
          <Image src={'/Assets/LoginAssets/logindogs.png'} alt="dogs" preview={false} />
        </div>

        {/* sign up form  */}
        <Card
          hoverable
          style={{
            width: isTab ? "100%" : "500px",
            height: isTab ? "100%" : "",
          }}
        >
          <Text
            style={{
              fontSize: "1.5em",
              display: "block",
              fontWeight: 500,
              marginBottom: "20px",
            }}
          >
            {" "}
            👋 Create an account !
          </Text>
          {/* <div style={{ width: "100%", textAlign: "center" }}>
            <button className="gsi-material-button" style={{ width: "100%" }}>
              <div className="gsi-material-button-state"></div>
              <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                  <Image src={googlesvg} preview={false} />
                </div>
                <span className="gsi-material-button-contents">
                  Signup with Google
                </span>
                <span style={{ display: "none" }}>Signup with Google</span>
              </div>
            </button>
          </div> */}
          {/* <Divider plain dashed style={{ borderColor: "lightgray" }}>
            Or Signup with email
          </Divider> */}
          <SignupForm handleSignup={handleSignup} form={form} captchaRef={captchaRef} />
          <Text style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            Already have an account ? <Link to={"/login"}>&nbsp;Login Now</Link>
          </Text>
        </Card>
      </div>
    </>
  );
};

export default Signup;
