import { Button, Col, Dropdown, Input, Row, Space, Typography } from "antd";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { usersAction } from "../State/actions";
import { useDispatch } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
const { Text } = Typography;

const UserHeader = ({ searchClientFunc }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "updatedAt",
      label: <Text>Updated At</Text>,
    },
    {
      key: "createdAt",
      label: <Text>Created At</Text>,
    },
    {
      key: "firstName",
      label: <Text>First Name</Text>,
    },
    {
      key: "lastName",
      label: <Text>Last Name</Text>,
    },
    {
      key: "email",
      label: <Text>Email</Text>,
    },
  ];

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sort", key);
    navigate(`?${searchParams.toString()}`);
    dispatch(usersAction.getUsersEvent(1, searchParams.get("search"), key));
  };

  return (
    <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
      <Col>
        <Button
          size="small"
          type="primary"
          disabled={searchParams.size === 0}
          onClick={() => {
            navigate(`/users`);
            dispatch(usersAction.getUsersEvent(1));
          }}
        >
          Clear Filters
        </Button>
      </Col>
      <Col>
        <Row>
          <Col style={{ marginRight: "10px" }}>
            <Dropdown
              menu={{
                items,
                onClick,
              }}
              style={{ cursor: "pointer" }}
            >
              <Button iconPosition="end" icon={<DownOutlined />} size="small">
                {`Sort By: ${searchParams.get("sort")
                    ? searchParams.get("sort")
                    : "updatedAt"
                  }`}
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Input
              placeholder="Search user details, role..."
              allowClear
              id="search"
              size="small"
              onChange={(e) => {
                e.target.value.trim().length > 0 &&
                  searchClientFunc(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UserHeader;
