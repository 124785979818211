import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersAction } from "./State/actions";
import {
  Button,
  Dropdown,
  FloatButton,
  Space,
  Table,
  theme,
  Typography,
} from "antd";
import { useRoute } from "../context/RouteContext";
import { debounceFun } from "../Common/Components/DebounceSearchFun";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UserHeader from "./Components/UserHeader";
import {
  CheckCircleTwoTone,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { roles } from "../Common/Components/roles";
import UserActionList from "./Components/UserActionList";
import useWarnings from "../Common/Components/useWarnings";
const { Text } = Typography;

const Users = () => {
  const [page, setPage] = useState(1);
  const { success, error, contextHolder } = useWarnings();
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const { useToken } = theme;
  const { token } = useToken();

  const dispatch = useDispatch();
  const { getUsers, totalUsers } = useSelector((state) => state.usersReducer);
  const { isLoadingAllUsers, isLoadingUserDetails } = useSelector(
    (state) => state.additionalloadingReducer
  );
  const { setSelectedKey } = useRoute();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const getUsersfn = (page, value) => {
    if (value) {
      dispatch(usersAction.getUsersEvent(page, value));
    } else if (searchParams.size === 0) {
      dispatch(usersAction.getUsersEvent(page));
    } else {
      dispatch(
        usersAction.getUsersEvent(
          page,
          searchParams.get("search"),
          searchParams.get("sort")
        )
      );
    }
  };

  const loadMoreData = (page) => {
    if (!isLoadingAllUsers && getUsers.length < totalUsers) {
      getUsersfn(page);
    } else setPage(1);
  };

  useEffect(() => {
    getUsersfn(1);
    setSelectedKey("GET_ALL_USERS");
  }, [dispatch]);

  const searchClientFunc = debounceFun((value) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("search", value);
    navigate(`?${searchParams.toString()}`);
    getUsersfn(1, value);
  }, 1000);

  useEffect(() => {
    return () => setPage(1);
  }, []);

  // Table columns
  const columns = [
    {
      title: "User Details",
      dataIndex: "name",
      key: "name",
      width: 300,
      align: "left",
      fixed: "left",
      render: (
        text,
        { firstName, lastName, email, contactNumber, verified, passwords }
      ) => (
        <>
          <Text style={{ display: "block" }}>
            {`${firstName} ${lastName}`}{" "}
            <CheckCircleTwoTone
              twoToneColor={"#52c41a"}
              style={{ display: verified ? "" : "none" }}
            />
          </Text>

          <Text style={{ display: "block" }}>{email}</Text>
          <Text
            style={{ display: "block" }}
          >{`+${contactNumber?.countryCode} ${contactNumber?.phoneNumber}`}</Text>
          {passwords ? (
            <Text
              style={{ display: "block" }}
              copyable={{ text: passwords?.codeWord }}
            >
              SYSTEM GENERATED-  {passwords?.codeWord}
            </Text>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Role Types",
      dataIndex: "roleType",
      key: "roleType",
      align: "center",
      width: 150,
      render: (roles) => roles?.join(", "), // Join roleType array to display roles
    },
    {
      title: "Current Role",
      dataIndex: "currentRole",
      key: "currentRole",
      align: "center",
      width: 100,
    },
  ];

  if (userDetails?.currentRole === "SUPER_ADMIN") {
    columns.push({
      title: "Action",
      key: "action",
      align: "center",
      width: 300,
      render: (_, { roleType, email, active, verified }) => (
        <UserActionList
          roleType={roleType}
          email={email}
          error={error}
          success={success}
          setLoading={setLoading}
          getUsersfn={getUsersfn}
          setPage={setPage}
          active={active}
          verified={verified}
        />
      ),
    });
  }
  const role = userDetails?.currentRole;
  const access = userDetails?.roleAccessDetails?.find((access) =>
    access?.hasOwnProperty(role)
  )?.[role];

  return (
    <div>
      {contextHolder}
      <Table
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={getUsers}
        onScroll={(e) => {
          const { scrollTop, scrollHeight, clientHeight } = e.target;
          if (scrollTop + clientHeight >= scrollHeight - 50) {
            // Added a check here to prevent loading if a page is already being loaded
            if (!isLoadingAllUsers) {
              const nextPage = page + 1;
              setPage(nextPage);
              loadMoreData(nextPage);
            }
          }
        }}
        loading={isLoadingAllUsers || loading || isLoadingUserDetails}
        pagination={false}
        scroll={{ y: 500 }}
        title={() => <UserHeader searchClientFunc={searchClientFunc} />}
        size="small"
        bordered
      />
      {access?.["CREATE_USER"] && (
        <FloatButton
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate("/createUser")}
          style={{
            right: 30,
          }}
        />
      )}
    </div>
  );
};

export default Users;
