import { Card, Col, Image, Row, theme, Tooltip, Typography } from "antd";
import React from "react";
import { services } from "../../Common/Components/Services";
import { PlusCircleFilled } from "@ant-design/icons";
import DateTime from "./DateTime";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
const { Title, Text } = Typography;
const { Meta } = Card;

/**
 * @component
 * @description This component renders a card displaying the details of a specific service, including its name, type, and the ability to select a date and time.
 * The card is interactive, allowing users to choose start and end times for the service through a date-time picker.
 *
 * @param {Object} service - The service object containing details like serviceName and serviceType.
 * @param {Function} setDateTime - Function to update the selected date and time in the parent state.
 * @param {Object} dateTime - The current selected date and time for the service, containing `checkInDateTime` and `checkOutDateTime`.
 * @param {Function} getTimeSlotsForParticularMonth - Function to fetch available time slots for the selected month.
 */

const EachDetailsServiceCard = ({
  service,
  setDateTime,
  dateTime,
  getTimeSlotsForParticularMonth,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  return (
    <Card hoverable style={{ borderRadius: 0, marginBottom: "10px" }}>
      <Row gutter={16} style={{ justifyContent: "center" }}>
        <Col sm={24} md={24} lg={12} xl={10}>
          {/* <Title
            key={uuidv4()}
            level={4}
            style={{ margin: "0", color: token.Primary }}
          >
            {services?.find((e) => e.key === service.serviceName).name}
            {service.serviceType === "addon" ? (
              <Tooltip title="Add On">
                <PlusCircleFilled style={{ fontSize: "15px" }} />
              </Tooltip>
            ) : (
              ""
            )}
          </Title> */}
          <Meta
            title={
              <>
                <Title level={4} style={{ margin: "0", color: token.Primary }}>
                  {services?.find((e) => e.key === service.serviceName).name}
                </Title>
                <Text>
                  {service.serviceType === "addon" ? (
                    <Tooltip title="Add On">
                      <PlusCircleFilled style={{ fontSize: "15px" }} />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Text>
              </>
            }
            description={
              bookappointmentdetailsbyID?.services?.find(
                (service) => service.serviceName === "petPickupAndDrops"
              ) && service?.serviceName === "petBoarding"
                ? "Since you've opted for both pet boarding and pickup and drop services, the dates and times chosen for pet boarding will also apply to the pickup and drop services. Please take note of this."
                : null
            }
          />
          <Title level={5}>Date and Time</Title>
          <DateTime
            setDateTime={setDateTime}
            dateTime={dateTime}
            getTimeSlotsForParticularMonth={getTimeSlotsForParticularMonth}
            serviceName={service?.serviceName}
          />
        </Col>
        <Col sm={0} md={0} lg={12} xl={10}>
          <Image
            preview={false}
            src={
              "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
            }
          />
        </Col>
      </Row>
    </Card>
  );
};

export default EachDetailsServiceCard;
