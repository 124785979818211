import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Input, Modal, Popconfirm, Progress, Typography } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dashboardAction } from "../State/actions";
import useWarnings from "../../Common/Components/useWarnings";
import { useSocket } from "../../context/SocketContext";
import dayjs from "dayjs";
const { TextArea } = Input;
const { Text, Paragraph } = Typography;

/**
 * @description
 * The `ActionCenterCard` component renders a card for each booking action in the dashboard. 
 * It provides options to reschedule, cancel, or track a booking, and displays relevant 
 * booking details like pet name, booking ID, and selected services.
 * 
 * @param {Object} props.action - The booking action data.
 */

const ActionCenterCard = ({ action }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const bookingIdTobecancelled = useRef();
    const [reason, setReason] = useState("");
    const dispatch = useDispatch();
    const { currentSocket } = useSocket();
    const { error, contextHolder } = useWarnings();
    const navigate = useNavigate();

    const showModal = (id) => {
        setIsModalOpen(true);
        bookingIdTobecancelled.current = id;
    };
    const handleCancel = () => setIsModalOpen(false);

    const handleTrack = (bookingId) => {
        currentSocket.emit("booking_connected", {
            bookingUniqueId: bookingId,
        });
        navigate(`/track/${bookingId}`);
    }

    const items = [
        {
            key: "reschedule",
            label:
                action.step > 1 ? (
                    <Text
                        onClick={() => {
                            localStorage.setItem("bookingID", action?.bookingID);
                            navigate("/book-appointment/step-2");
                        }}
                    >
                        Reschedule Booking
                    </Text>
                ) : (
                    <Text>Not allowed to reschedule</Text>
                ),
        },
        {
            key: "cancel",
            label: !action?.status?.cancellation?.requested ? (
                <>
                    <Text onClick={() => showModal(action?.bookingID)}>
                        Request to cancel Booking
                    </Text>
                </>
            ) : action?.status?.cancellation?.approved ? (
                <Text>Cancellation request approved</Text>
            ) : (
                <Text>Cancellation request raised</Text>
            ),
        },
    ];

    // Handles the continuation of the booking process depending on the action step and boarding center match.
    const handleContinue = (action) => {
        if (action.isBoardingCenterMatched) {
            localStorage.setItem("newBookAppointment", false);
            localStorage.setItem("bookingID", action?.bookingID);
            navigate(`/book-appointment/step-${action.step}`);
        }
    };

    return (
        <div style={{ margin: "10px" }}>
            {contextHolder}
            <Card
                extra={
                    action?.step > 3 && (
                        <Dropdown
                            menu={{
                                items
                            }}
                        >
                            <EllipsisOutlined style={{ fontSize: "20px" }} />
                        </Dropdown>
                    )
                }
                title={t(`${action?.ActionTitle}`)}
                hoverable
                style={{ boxShadow: "0px 0px 10px -1px lightgrey", minHeight: "260px", overflow: "scroll" }}
            >
                <Progress
                    strokeColor={"#B96435"}
                    size={"small"}
                    percent={action?.step === 2 ? 50 : action?.step === 3 ? 80 : 100}
                    showInfo={false}
                />
                <Text style={{ display: "block" }}>
                    {t("Pet Name")} : {action?.petDetails?.name || "--"}
                </Text>
                <Text style={{ display: "block" }} copyable>
                    {t("booking_id")} : {action?.bookingID || "--"}
                </Text>
                <Text style={{ display: "block" }}>
                    Created On : {dayjs(action?.createdAt).format("MMM D, YYYY h:mm A")}
                </Text>
                <Paragraph ellipsis={{ rows: 1 }}>
                    {t('selected_services')} : {action?.services?.map(e => t(e.serviceName)).join(', ')}
                </Paragraph>
                
                {action?.step === 3 && action?.rescheduleBooking?.count === 1 &&
                    <Text>
                        {action?.rescheduleBooking?.rescheduleUpto}
                    </Text>
                }
                <div style={{ textAlign: "end" }}>
                    <Button
                        size="small"
                        onClick={() => {
                            if (action?.step === 4) handleTrack(action.bookingID)
                            else handleContinue(action);
                        }}
                        disabled={action?.isBoardingCenterMatched === true ? false : true}
                    >
                        {action?.step === 4
                            ? t("track")
                            : action?.step === 3 && action?.rescheduleBooking.count === 1
                                ? t("Reschedule Booking")
                                : t("continue_booking")}{" "}
                        ❯
                    </Button>
                </div>
            </Card>
            <Modal
                title="Reason for cancellation"
                maskClosable={true}
                onCancel={handleCancel}
                footer={() => (
                    <Popconfirm title="Are you sure to raise cancel request?" onConfirm={() => {
                        if (reason === "") return error("Please enter the reason for raising cancel request")
                        dispatch(dashboardAction.requestCancellationEvent(bookingIdTobecancelled.current, { reason }));
                    }}>
                        <Button>Request for cancellation</Button>
                    </Popconfirm>
                )}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                open={isModalOpen}
            >
                <TextArea rows={3} onChange={e => setReason(e.target.value)} />
            </Modal>
        </div>
    );
};

export default ActionCenterCard;
