import React, { memo, useEffect } from 'react'
import Banner from './Banner'
import Actioncentre from './Actioncentre'
import YourInformation from './YourInformation'
import HappyFaces from './HappyFaces'
import ServiceHistoryLine from './ServiceHistoryLine'
import ServiceHistoryPie from './ServiceHistoryPie'
import YourSubscription from './YourSubscription'
import SpecialforYou from './SpecialforYou'
import { Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { dashboardAction } from '../State/actions'

const UserHome = memo(() => {
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch(dashboardAction.clearActionCenter());
        }
    }, []);

    return (
        <>
            <Banner />
            <Actioncentre />
            <Row
                gutter={[16, 16]}
                justify={"center"}
                style={{ alignItems: "center", marginBottom: "20px" }}
            >
                <Col xs={24} sm={24} md={24} lg={12}>
                    <YourInformation />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <HappyFaces />
                </Col>
            </Row>
            <Row
                gutter={[16, 16]}
                justify={"center"}
                style={{ alignItems: "center", marginBottom: "20px" }}
            >
                <Col xs={24} sm={24} md={24} lg={12}>
                    <ServiceHistoryLine />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <ServiceHistoryPie />
                </Col>
            </Row>
            <Row
                gutter={[16, 16]}
                justify={"center"}
                style={{ alignItems: "center", marginBottom: "20px" }}
            >
                <Col xs={24} sm={24} md={24} lg={12}>
                    <YourSubscription />
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <SpecialforYou />
                </Col>
            </Row>
        </>
    )
})

export default UserHome
