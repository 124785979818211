import { Modal } from "antd";
import React from "react";

const CommonModal = ({
  body,
  isModalOpen,
  handleOk,
  handleCancel,
  width,
  maskClosable,
  keyboard,
  title = "",
  height,
  closeIcon = false
}) => {
  return (
    <>
      <Modal
        width={width}
        style={{ padding: 0 }}
        title={title}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        styles={{ body: { height: height, overflow: "scroll" } }}
        maskClosable={maskClosable}
        closeIcon={closeIcon}
        keyboard={keyboard}
      >
        {body()}
      </Modal>
    </>
  );
};

export default CommonModal;
