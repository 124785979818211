import { Card, Collapse, Timeline } from "antd"

export const lightTheme = {
    token: {
        fontFamily: "Poppins,sans-serif",
        colorPrimary: "#B96435",
        lineHeight: 2,
        controlHeight: 40,
        colorBgBase: '#ffffff',
        colorText: "#000000",
        logoBg: "#DAE3FF",
        Primary: '#B96435',
        Secondary: '#5A7BCB',
        appbluecolor: "#5A7BCB",
        header: '#00548B',
        textPrimary: '#4274BA',
        transparent:"transparent",
        White: '#FFFFFF',
        LightBlue: "rgb(215,233,251)",
        Red: '#F02000',
        Gray: 'rgba(0, 0, 0, 0.25)',
        Black: '#000000',
        GreyShadeLight: '#707070',
        lightYellow: "#FFFFED",
        lightGreen: "#90EE90",
        Green:"#008000",
        title: "#B96435"
    },
    components: {
        FloatButton: {
            colorBgElevated: "#B96435",
            colorText: "white",
            fontSizeIcon: 10,
            controlHeightLG: 35,
            borderRadiusLG: 2,
        },
        Layout: {
            controlHeight: 40,
            bodyBg: "white",
            headerBg: "white"
        },
        Menu: {
            itemSelectedBg: "#B96435",
            itemSelectedColor: "white",
            itemColor:"white",
            subMenuItemBg:'#5A7BCB',
            popupBg: '#A9A9A9',
            itemHeight: 40,
        },
        Steps: {
            fontSizeLG: 13,
            titleLineHeight: 22,
        },
        Tabs: {
            verticalItemMargin: 0
        },
        Timeline: {
            itemPaddingBottom:0
        },
        Collapse: {
            headerPadding:0
        }
    },
}

export const darkTheme = {
    // #1E293B- used inside the cards, buttons
    // #0B1120 - used as the black color
    token: {
        fontFamily: "Poppins,sans-serif",
        colorPrimary: "#B96435",
        lineHeight: 2,
        controlHeight: 40,
        colorBgBase: '#0B1120',
        colorBgContainer: "#1E293B", //inside cards, buttons
        banner: "#1E293B",
        colorText: "#FFFFFF",
        logoBg: "#1E293B",
        colorBorderSecondary: "none",
        colorBorder: "lightGrey",
        transparent:"transparent",
        Primary: '#B96435',
        Secondary: '#1E293B',
        header: '#00548B',
        appbluecolor: "#5A7BCB",
        textPrimary: '#4274BA',
        White: '#FFFFFF',
        LightBlue: "rgba(0, 0, 0, 0.25)",
        Red: '#F02000',
        Gray: 'rgba(0, 0, 0, 0.25)',
        Black: '#141414',
        GreyShadeLight: '#707070',
        lightYellow: "#FFFFED",
        lightGreen: "#90EE90",
        Green:"#008000",
        title: "#FFFFFF",

    },
    components: {
        FloatButton: {
            colorBgElevated: "#B96435",
            colorText: "white",
            fontSizeIcon: 10,
            controlHeightLG: 35,
            borderRadiusLG: 2,
        },
        Layout: {
            controlHeight: 40,
            bodyBg: "#0B1120",
            headerBg: "#0B1120"
        },
        Menu: {
            itemSelectedBg: "#B96435",
            itemSelectedColor: "white",
            itemHeight: 40
        },
        Steps: {
            fontSizeLG: 13,
            titleLineHeight: 22,
        },
        Tabs: {
            verticalItemMargin: 0
        },
        Calendar: {
            fullPanelBg: "#0B1120",
            fullBg: "#0B1120",
        },
        Collapse: {
            contentBg: "#0B1120"
        },
        List: {
            colorTextDescription: "grey"
        },
        Table: {
            borderColor: "#525252"
        },
        Collapse: {
            headerPadding:0
        }
    },
}