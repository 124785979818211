import { CaretRightFilled } from "@ant-design/icons";
import { Button, Card, Progress } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const OtherActionCard = () => {
    const { t } = useTranslation();
    const { actionCenterDetails } = useSelector((str) => str.dashboardReducer);

    const navigate = useNavigate();
    return (
        <div style={{ margin: "10px" }}>
            <Card
                title={t(`${actionCenterDetails?.userAction?.title}`)}
                style={{
                    boxShadow: "0px 0px 10px -1px lightgrey",
                    height: "260px",
                    overflow: "scroll",
                }}
            >
                {
                    actionCenterDetails && actionCenterDetails?.userAction?.actions?.map(e => (
                        <Button size="small" style={{ display: "block" }} type="link" onClick={() => navigate(e?.link)}>
                            {e?.message}
                        </Button>
                    ))
                }
            </Card>
        </div>
    );
};

export default OtherActionCard;
