import { Button, Checkbox, Form, Input, theme, Typography } from "antd";
import React, { useRef, useState } from "react";
import styles from "../Styles/login.module.css";
import "../Styles/login.css";
import { useSelector } from "react-redux";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { signupApi } from "../utils/api";
import ReCAPTCHA from "react-google-recaptcha";
const { Text } = Typography;

/**
 * LoginForm component provides a form for users to input their email and password for authentication.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleLogin - The function to be called when the form is submitted.
 * @param {Object} props.form - The form instance from Antd's Form component.
 */

const LoginForm = ({ handleLogin, form, rememberMe, setRememberMe, captchaRef }) => {
  const [password, setPassword] = useState({
    field: false,
    buttonName: "Continue",
  });
  const { isLoading } = useSelector((state) => state.loadingReducer);
  const [loading, setLoading] = useState(false);
  const { useToken } = theme;
  const { token } = useToken(theme);
  const navigate = useNavigate();


  // Function to handle the email submission
  const handleEmailSubmit = async (values) => {
    setPassword({ field: true, buttonName: "Login" });
    // setLoading(true);
    // try {
    //   let response = await signupApi.userCheck(values.email);
    //   if (response?.data?.info?.USER_EXIST) {
    //     setPassword({ field: true, buttonName: "Login" });
    //   } else if (!response?.data?.info?.USER_EXIST) {
    //     setPassword({ field: false, buttonName: "Sign Up" });
    //     navigate("/signup");
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.error("Error checking user existence", error);
    //   setLoading(false);
    // }
  };


  return (
    <>
      <Form
        style={{ width: "100%" }}
        form={form}
        layout="vertical"
        onFinish={password.field ? handleLogin : handleEmailSubmit} // Conditionally handle form submission
      >
        <Form.Item
          required
          name={"email"}
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "Please enter a valid email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            autoFocus
            onChange={() =>
              setPassword({ field: false, buttonName: "Continue" })
            }
            placeholder="Email id"
            className={styles.formInput}
          />
        </Form.Item>

        {password.field && (
          <>
            <Form.Item
              required
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter Your Password"
                className={styles.formInput}
                autoFocus
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                >
                  Remember me
                </Checkbox>
                <Link to={"/forgot-password"}>
                  <LockOutlined /> Forgot password ?
                </Link>
              </div>
            </Form.Item>
            <Form.Item
              required
              name={"recaptcha"}
              rules={[
                {
                  required: true,
                  message: "Please verify",
                },
              ]}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                ref={captchaRef}
                style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
              />
            </Form.Item>
          </>
        )}

        <Button
          htmlType="submit"
          style={{
            width: "100%",
            height: "45px",
            backgroundColor: token.Secondary,
            color: "white",
            fontSize: "18px",
          }}
          loading={isLoading || loading}
        >
          {password.buttonName}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
