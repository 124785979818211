import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, theme, Typography, Image, Divider, Spin } from 'antd'
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { services } from '../../Common/Components/Services';
import { useDispatch, useSelector } from 'react-redux';
import { settingsAction } from '../State/actions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../../Spinner/Spinner';
import { v4 as uuidv4 } from 'uuid';
const { Text } = Typography;

/**
 * @description
 * The `PastBookings` component displays a list of past bookings using a card layout. It supports infinite scrolling to load more bookings as the user scrolls down. Each booking is shown with details such as the boarding center name, drop address, booking ID, and status.
 * 
 * @param {Object} props - The component props.
 * @param {Function} props.setPage - Function to set the current page number for pagination.
 * @param {number} props.page - The current page number.
 */

const PastBookings = ({ setPage, page }) => {
    const { allBookings, totalBookings } = useSelector((str) => str.settingsReducer);
    const { isLoadingAllBookings } = useSelector(str => str.loadingReducer);
    // const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { useToken } = theme;
    const { token } = useToken();

    const loadMoreNotifications = () => {
        if (allBookings.length < totalBookings) {
            const nextPage = page + 1;
            dispatch(settingsAction.getAllBookingsEvent(nextPage, "past"));
            setPage(nextPage);
        }
    }

    useEffect(() => {
        return () => {
            setPage(1);
        }
    }, []);

    return (
        allBookings ?
            <div id='pastScroll' style={{ height: window.innerHeight*0.7, overflow: "auto" }}>
                <InfiniteScroll
                    scrollableTarget="pastScroll"
                    dataLength={allBookings.length}
                    hasMore={allBookings.length < totalBookings}
                    next={loadMoreNotifications}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    loader={
                        isLoadingAllBookings && <div style={{ textAlign: 'center' }}><Spin size='large' /></div>
                    }
                >
                    {allBookings?.map(booking => (
                        <Card key={uuidv4()} hoverable style={{ borderRadius: 0, width: "85%", marginBottom: "1.2em", borderColor: "lightgrey" }}>
                            <Row>
                                <Col span={4}>
                                    <Image width={"90%"} src='https://cdn.pixabay.com/photo/2018/10/01/09/21/pets-3715733_640.jpg' preview={false} />
                                </Col>
                                <Col span={12}>
                                    <Text style={{ fontWeight: 600, display: "block" }}>{booking.boardCenterName}</Text>
                                    <Text style={{ display: "block", fontSize: "12px" }} >{booking?.dropAddress?.city}</Text>
                                    <Text style={{ color: token.GreyShadeLight, fontSize: "12px", display: "block" }}>ORDER#{booking?.bookingUniqueId}</Text>
                                    {/* <Button style={{border:"none",textAlign:"start"}} size='small'>VIEW DETAILS</Button> */}
                                </Col>
                                <Col span={8} style={{ textAlign: "end" }}>
                                    {booking?.status?.cancellation?.requested ?
                                        booking?.status?.cancellation?.approved ? `Cancelled on ${dayjs(booking?.status?.cancellation?.dateApproved).format("MMM D, YYYY h:mm A")}` : `Cancellation raised on ${dayjs(booking?.status?.cancellation?.dateRequested).format("MMM D, YYYY h:mm A")}` :
                                        `Completed on ${dayjs(booking?.status?.petBoarding_status?.booking_confirmarion?.date).format("MMM D, YYYY h:mm A")} ${<CheckCircleOutlined style={{ color: token.Green }} />}`
                                    }
                                </Col>
                            </Row>
                            <Divider />
                            <Row>
                                <Col span={18} style={{ textAlign: "left" }}>
                                    {booking?.services?.map((e) => (
                                        <Text style={{ display: "block" }}>{`${services.find((service) => service.key === e.serviceName).name
                                            } FROM ${dayjs(e.slots?.checkInDateTime).format(
                                                "MMM D, YYYY h:mm A"
                                            )} TO ${dayjs(e?.slots?.checkOutDateTime).format(
                                                "MMM D, YYYY h:mm A"
                                            )}`}</Text>)
                                    )}
                                </Col>
                                <Col span={6} style={{ textAlign: "end" }}>
                                    <Text style={{ display: "block" }}>{booking?.services?.every(e => e.paymentId) ? `Total Paid: ₹${booking?.totalPrice}` : ""}</Text>
                                    <Button size='small'
                                        type='primary'
                                        style={{ borderRadius: 0 }}
                                    >
                                        {booking?.status?.cancellation?.requested ?
                                            booking?.status?.cancellation?.approved ? 'CANCELLATION APPROVED' : 'CANCELLATION RAISED' :
                                            'Completed'
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Card >
                    ))}
                </InfiniteScroll>
            </div>
            : isLoadingAllBookings ? (
                <Spinner />
            ) : ""
    )
}

export default PastBookings
