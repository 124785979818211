import { FloatButton, Switch, Table, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { boardCenterAction } from "./State/actions";
import { v4 as uuidv4 } from "uuid";
import { services } from "../Common/Components/Services";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useWarnings from "../Common/Components/useWarnings";
import { useRoute } from "../context/RouteContext";
const { Text } = Typography;

/**
 * BoardingCenter component displays a list of boarding centers in a table format.
 * It provides options to edit existing centers or add a new one.
 */
const BoardingCenter = () => {
  const dispatch = useDispatch();
  const {
    allBoardcenters,
    updateBoardCenter,
    addBoardCenter,
    updateBoardCenterErr,
    addBoardCenterErr,
  } = useSelector((str) => str.boardCenterReducer);
  const {
    isLoadingAllBoardCenters,
    isLoading,
    isLoadingQuestionById,
  } = useSelector((str) => str.loadingReducer);
  const { setSelectedKey } = useRoute();

  const navigate = useNavigate();
  const { success, error, contextHolder } = useWarnings();

  useEffect(() => {
    dispatch(boardCenterAction.getAllBoardCentersEvent());
  }, [updateBoardCenter, addBoardCenter]);

  // useEffect(() => {
  //   if (updateBoardCenter || addBoardCenter) {
  //     success("Board Center updated successfully");
  //     dispatch(boardCenterAction.clearBoardCenter());
  //   } else if (updateBoardCenterErr || addBoardCenterErr) {
  //     console.log('iam cmng')
  //     error(updateBoardCenterErr || addBoardCenterErr);
  //     dispatch(boardCenterAction.clearBoardCenter());
  //   }
  // }, [
  //   updateBoardCenterErr,
  //   updateBoardCenter,
  //   addBoardCenter,
  //   addBoardCenterErr,
  // ]);

  useEffect(()=> {
    setSelectedKey("CRUD_BOARDING_CENTE")
  },[])

  if (isLoadingAllBoardCenters || isLoading || isLoadingQuestionById) {
    return <Spinner />;
  }

  const columns = [
    {
      title: "Board center details",
      key: uuidv4(),
      width: "20%",
      render: ({ _id, name, code_word }) => (

        <>
          <Text style={{ display: "block" }}>
            {name}-{code_word}
          </Text>
          <Text style={{ display: "block" }}>{_id}</Text>
        </>
      ),
    },
    {
      title: "Boardcenter head and id",
      key: uuidv4(),
      width: "20%",
      render: ({ boardCenterHeadName, boardCenterHeadId }) => (
        <Text style={{ display: "block" }}>
          {boardCenterHeadName}-{boardCenterHeadId}
        </Text>
      ),
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "address",
      width: "30%",
    },
    {
      title: "Services and its price",
      width: "20%",
      render: ({ servicesPresent }) =>
        servicesPresent?.map((e) => (
          <Tag>
            {services.find((ser) => ser.key === e.name).name}-{e.price}
          </Tag>
        )),
    },
    {
      title: "Action",
      width: "10%",
      render: ({ boardCenterUniqueId }) => (
        <>
          <Switch size="small" />
          <EditOutlined
            style={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }}
            onClick={() => navigate(`/boardingCenter/${boardCenterUniqueId}`)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        bordered
        dataSource={allBoardcenters}
        pagination={false}
      />
      <FloatButton
        icon={<PlusOutlined />}
        onClick={() => navigate("/addBoardingCenter")}
        type="primary"
        style={{
          right: 30,
        }}
      />
    </>
  );
};

export { BoardingCenter };
