import { bookAppointmentConstants } from "./action-types";

const initialState = {
  updateddetails: false,
  updateddetailsError: false,
  details: false,
  detailsError: false,
  bookappointmentdetailsbyID: false,
  bookappointmentdetailsbyIDError: false,
  bookappointmentdetails: false,
  bookappointmentdetailsError: false,
  paymentdetails: false,
  paymentError: false,
  paymentdetailsBooking: false,
  paymentBookingError: false,
  slotDetails: false,
  slotDetailsError: false,
  updateServices: false,
  updateServicesErr: false,
};

const bookAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case bookAppointmentConstants.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        updateddetails: action.data,
        updateddetailsError: false,
      };
    case bookAppointmentConstants.UPDATE_DETAILS_FAILURE:
      return {
        ...state,
        updateddetails: false,
        updateddetailsError: action.error,
      };
    case bookAppointmentConstants.GET_BOOKING_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        bookappointmentdetailsbyID: action.data,
        bookappointmentdetailsbyIDError: false,
      };
    case bookAppointmentConstants.GET_BOOKING_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        bookappointmentdetailsbyID: false,
        bookappointmentdetailsbyIDError: action.error,
      };
    case bookAppointmentConstants.GET_BOOKING_DETAILS_SUCCESS:
      return {
        ...state,
        bookappointmentdetails: action.data,
        bookappointmentdetailsError: false,
      };
    case bookAppointmentConstants.GET_BOOKING_DETAILS_ERROR:
      return {
        ...state,
        bookappointmentdetails: false,
        bookappointmentdetailsError: action.error,
      };
    case bookAppointmentConstants.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentdetails: action.data,
        paymentError: false,
      };
    case bookAppointmentConstants.UPDATE_SELECT_SERVICES_SUCCESS:
      return {
        ...state,
        updateServices: action.data,
        updateServicesErr: false,
      };
    case bookAppointmentConstants.UPDATE_SELECT_SERVICES_FAILURE:
      return {
        ...state,
        updateServices: false,
        updateServicesErr: action.error,
      };
    case bookAppointmentConstants.PAYMENT_SUCCESS_BOOKING_ID:
      return {
        ...state,
        paymentdetailsBooking: action.data,
        paymentBookingError: false,
      };
    case bookAppointmentConstants.PAYMENT_FAILURE_BOOKING_ID:
      return {
        ...state,
        paymentdetailsBooking: false,
        paymentBookingError: action.error,
      };
    case bookAppointmentConstants.RESET_DETAILSBYID:
      return {
        ...state,
        bookappointmentdetailsbyID: false,
        bookappointmentdetailsbyIDError: false,
      };
    case bookAppointmentConstants.RESET_UPDATE_DETAILS:
      return {
        ...state,
        updateddetails: false,
        updateddetailsError: false,
      };
    case bookAppointmentConstants.GET_SLOT_DETAILS_ERROR:
      return {
        ...state,
        slotDetails: false,
        slotDetailsError: action.error,
      };
    case bookAppointmentConstants.GET_SLOT_DETAILS_SUCCESS:
      return {
        ...state,
        slotDetails: action.data,
        slotDetailsError: false,
      };
    default:
      return state;
  }
};
export { bookAppointmentReducer };
