import React, { useState } from "react";
import { Button, Row, Form, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bookAppointmentAction } from "../State/bookAppointmentActions";
import Spinner from "../../Spinner/Spinner";
import LocationDetails from "./LocationDetails";
import useDetailsEffects from "./useDetailsEffects";
import { NotFound } from "../../Common/Components/NotFound";
import useCheckEffects from "./useCheckEffects";
import EachDetailsServiceCard from "./EachDetailsServiceCard";
import { handleFinish } from "../utils/handleFinishDetails";

/**
 * @param - no params needed
 * @description This component handles the display and management of booking details for an appointment.
 * It allows users to view and edit service details, select time slots, and manage location details.
 * The component also manages the state for date and time, addresses, and slots by month.
 * If it's a new booking, the handleCurrentSubscribe function will create a POST request; otherwise, services can be updated.
 * The handleCurrentSubscribe function is called when the save button is clicked.
 */

const Details = () => {
  const [dateTime, setDateTime] = useState({});
  const [address, setAddress] = useState({
    pickupaddress: "",
    dropaddress: "",
    boardingCenterAddress: "",
  });
  const [slotByMonth, setSlotByMonth] = useState({ date: "", serviceName: "" });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    isLoadingParentBoardingCenter,
    isLoadingUserDetails,
    isLoadingBooking,
    isLoadingUpdatedServices,
  } = useSelector((str) => str.loadingReducer);
  const { bookappointmentdetailsbyID } = useSelector(
    (str) => str.bookAppointmentReducer
  );
  const booking_id = localStorage.getItem("bookingID");
  const { parentBoardingDetails } = useSelector((str) => str.profileReducer);

  const getTimeSlotsForParticularMonth = (data) => {
    setSlotByMonth({
      ...slotByMonth,
      date: data.date,
      serviceName: data.serviceName,
    });
    dispatch(bookAppointmentAction.getSlotDetailsEvent(data));
  };

  useDetailsEffects({ setAddress, setDateTime });
  useCheckEffects("Book Appointment");

  if (
    isLoading ||
    isLoadingParentBoardingCenter ||
    isLoadingUserDetails ||
    isLoadingBooking ||
    isLoadingUpdatedServices
  ) {
    return <Spinner />;
  }
  // if the user selected location is not servicable
  if (Object.keys(parentBoardingDetails)?.length === 0) {
    return (
      <NotFound
        title={
          "Services are not available at this pincode. Please choose another location."
        }
        continueTo={"/"}
      />
    );
  }
  // if there are no services selected
  if (!booking_id) {
    return (
      <NotFound
        title={"Please select services to continue"}
        continueTo={"/book-appointment/step-1"}
      />
    );
  }

  return (
    <>
      <Row gutter={16} style={{ marginTop: "20px" }}>
        <Col md={24} lg={16}>
          {bookappointmentdetailsbyID?.services
            ?.filter((service) => service.serviceName !== "petPickupAndDrops")
            ?.map((service) => (
              <EachDetailsServiceCard
                service={service}
                handleFinish={handleFinish}
                setDateTime={setDateTime}
                dateTime={dateTime}
                getTimeSlotsForParticularMonth={getTimeSlotsForParticularMonth}
                address={address}
              />
            ))}
        </Col>
        <Col
          md={24}
          lg={8}
          style={{ position: "sticky", top: "150px", height: "max-content" }}
        >
          <LocationDetails address={address} setAddress={setAddress} />
        </Col>
      </Row>
      <Row justify={"space-between"} style={{ marginTop: "1.2em" }}>
        <Button
          type="primary"
          onClick={() => {
            localStorage.setItem("newBookAppointment", false);
            navigate("/book-appointment/step-1");
          }}
        >
          ❮ Previous
        </Button>
        <Form
          form={form}
          onFinish={() =>
            handleFinish(
              address,
              form,
              bookappointmentdetailsbyID,
              navigate,
              dispatch,
              dateTime
            )
          }
        >
          <Button
            type="primary"
            htmlType="submit"
            disabled={
              !bookappointmentdetailsbyID?.services?.some(
                (service) =>
                  dateTime[service?.serviceName]?.checkInDateTime &&
                  dateTime[service?.serviceName]?.checkOutDateTime
              )
            }
          >
            Next ❯
          </Button>
        </Form>
      </Row>
    </>
  );
};

export default Details;
