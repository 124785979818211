import { Card, Divider, Form, Image, Typography, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Styles/login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { signupAction } from "./State/actions";
import { verifySignature } from "../Common/Verify";
import "./Styles/login.css";
import { googleAction } from "./State/googleactions";
import LoginForm from "./Components/LoginForm";
import LoginWithGoogle from "./Components/LoginWithGoogle";
const { Text } = Typography;
/**
 * Login component provides a user interface for logging into the application.
 * @description
 * - **Google Login**: Integrates with Google login functionality as an alternative login method.
 * - **Navigation**: Redirects authenticated users to the appropriate page based on their role (e.g., USER, ADMIN, SUPER_ADMIN).
 */

const Login = () => {
  const isTab = useMediaQuery({ maxWidth: 576 });
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signupError } = useSelector((str) => str.signupReducer);
  const [messageApi, contextHolder] = message.useMessage();
  const { googleError } = useSelector((str) => str.googleReducer);
  const [rememberMe, setRememberMe] = useState(false);
  const captchaRef = useRef();

  useEffect(() => {
    if (signupError) {
      messageApi.open({
        type: "error",
        content: signupError,
      });
      form.setFieldValue("password", "");
      captchaRef.current.reset();
    }
    dispatch(signupAction.clearSignupData());
    if (googleError) {
      messageApi.open({
        type: "error",
        content: googleError,
      });
    }
    dispatch(googleAction.clearGoogleData());
    // eslint-disable-next-line
  }, [signupError, googleError]);

  let token = localStorage.getItem("authToken");

  const authentication = verifySignature(token);

  useEffect(() => {
    if (authentication) {
      // if (role === "USER") navigate("/");
      // else if (role === "SUPER_ADMIN" || role === "ADMIN" || role === "CAPTAIN")
      navigate("/");
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [authentication]);

  useEffect(() => {
    const rememberedUsername = localStorage.getItem("userName");
    if (rememberedUsername) {
      form.setFieldValue("email", rememberedUsername);
      setRememberMe(true);
    }
  }, []);

  // when userc logs in manually
  const handleLogin = (formvalues) => {
    dispatch(signupAction.loginEvent({ ...formvalues, type: "web" }));
    // captchaRef.current.reset();
    if (rememberMe) {
      localStorage.setItem("userName", formvalues?.email);
    } else {
      localStorage.removeItem("userName");
    }
    // form.resetFields();
  };

  return (
    <div
      className={styles.loginpage}
      style={{
        flexDirection: "column",
        backgroundImage: `url("/Assets/LoginAssets/loginscreen.png")`,
      }}
    >
      {contextHolder}
      <div
        style={{ display: isTab ? "none" : "", width: isTab ? "" : "500px" }}
      >
        <Image
          src={"/Assets/LoginAssets/logindogs.png"}
          alt="dogs"
          preview={false}
        />
      </div>
      <Card
        hoverable
        style={{ width: isTab ? "100%" : "500px", height: isTab ? "100%" : "" }}
      >
        <Text
          style={{
            fontSize: "1.3em",
            display: "block",
            fontWeight: 500,
            marginBottom: "20px",
          }}
        >
          {" "}
          👋 Hi, Welcome Back to My Pet !
        </Text>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginForm
            form={form}
            handleLogin={handleLogin}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            captchaRef={captchaRef}
          />
          <Divider plain dashed style={{ borderColor: "lightgray" }}>
            Or Continue with Google
          </Divider>
          <LoginWithGoogle />
        </div>

        <Text style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          Don't have an account ? <Link to={"/signup"}>&nbsp;Sign Up.</Link>
        </Text>
      </Card>
    </div>
  );
};

export { Login };
