import { ReactComponent as CustomDashboardSVG } from "../../Assets/Header/dashboard.svg";
import { ReactComponent as CustomServicesSVG } from "../../Assets/Header/services.svg";
import { ReactComponent as CustomSettingsSVG } from "../../Assets/Header/settings.svg";
import { ReactComponent as CustomLogoutSVG } from "../../Assets/Header/logout.svg";
import { PiBathtub, PiBookFill } from "react-icons/pi";
import styles from "../Styles/iconstyles.module.css";
import { v4 as uuidv4 } from "uuid";
import Icon, { SafetyCertificateOutlined } from "@ant-design/icons";
import {
  MdCalendarMonth,
  MdHelp,
  MdOutlinePayments,
  MdSubscriptions,
  MdSupportAgent,
} from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { LiaWarehouseSolid } from "react-icons/lia";
import { RiHome2Fill, RiMedalFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { BsHouse } from "react-icons/bs";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { LuUsers } from "react-icons/lu";

const CustomDashboardIcon = (props) => (
  <Icon
    style={{ fontSize: "30px" }}
    component={CustomDashboardSVG}
    {...props}
  />
);
const CustomServicesIcon = (props) => (
  <Icon style={{ fontSize: "30px" }} component={CustomServicesSVG} {...props} />
);
const CustomSettingsIcon = (props) => (
  <Icon style={{ fontSize: "30px" }} component={CustomSettingsSVG} {...props} />
);
const CustomLogoutIcon = (props) => (
  <Icon style={{ fontSize: "30px" }} component={CustomLogoutSVG} {...props} />
);
const OptionsComponent = () => {
  const { userDetails } = useSelector((state) => state.navbarReducer);
  const role = userDetails?.currentRole;
  const access = userDetails?.roleAccessDetails?.find((access) =>
    access?.hasOwnProperty(role)
  )?.[role];

  const optionMenu = {
    USER: [
      {
        label: "Dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
        routekey: "Dashboard",
      },
      {
        label: "Book Appointment",
        key: "CRUD_BOOK_APPOINTMENT",
        icon: (
          <MdCalendarMonth
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "book-appointment/step-1",
        routekey: "Book Appointment",
      },
      {
        label: "Services",
        key: uuidv4(),
        icon: <CustomServicesIcon />,
        className: styles.icon,
        routekey: "Services",
      },

      // {
      //   label: "Templates",
      //   key: "TEMPLATES",
      //   icon: <CustomSettingsIcon />,
      //   className: styles.icon,
      // },
      // {
      //   label: "Drafts",
      //   key: "DRAFTS",
      //   icon: <CustomSettingsIcon />,
      //   className: styles.icon,
      // },
      // {
      //   label: "Buy Sale",
      //   key: "BUY_SALE",
      //   icon: <CustomSettingsIcon />,
      //   className: styles.icon,
      // },
      {
        label: "Settings",
        key: "SETTINGS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
        path: "accounts/parentProfile",
      },
    ],
    SUPPORT: [
      {
        label: "dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "serviceDetails",
        key: "SERVICE_DETAILS",
        icon: <CustomServicesIcon />,
        className: styles.icon,
      },
      {
        label: "settings",
        key: "SETTINGS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
      },
      {
        label: "Logout",
        key: uuidv4(),
        icon: <CustomLogoutIcon />,
        className: styles.icon,
      },
    ],
    CAPTAIN: [
      {
        label: "Dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      // {
      //   label: "Bookings",
      //   key: "CAPTAIN_DETAILS",
      //   icon: <CustomServicesIcon />,
      //   className: styles.icon,
      //   path: "bookings",
      // },
      {
        label: "Templates",
        key: "TEMPLATES",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
      },
      {
        label: "Drafts",
        key: "DRAFTS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
      },
      {
        label: "Buy Sale",
        key: "BUY_SALE",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
      },
      {
        label: "settings",
        key: "SETTINGS",
        icon: <CustomSettingsIcon />,
        className: styles.icon,
        path: "accounts/parentProfile",
      },
    ],
    ADMIN: [
      {
        label: "dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "Service Details",
        key: "SERVICE_DETAILS",
        icon: <CustomServicesIcon />,
        className: styles.icon,
        path: "services/newAppointments",
      },
      {
        label: "Requests for cancellation of bookings",
        key: "BOOKING_CANCELLATION_APPROVAL",
        icon: (
          <PiBookFill
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "cancellationRequests",
      },
      {
        label: "Support tickets",
        key: "CRUD_TICKET",
        icon: (
          <MdSupportAgent
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "tickets",
      },
      {
        // PAYMENT_STATUS
        label: "Payment Links",
        key: "PAYMENT_STATUS",
        icon: (
          <MdOutlinePayments
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: `paymentLinks`,
      },
      {
        label: "Update Boarding center",
        key: "UPDATE_BOARDING_CENTER",
        icon: (
          <BsHouse
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: `boardingCenter/${userDetails?.boardCenterUniqueId}`,
      },
      {
        label: "Captains",
        key: "CRUD_CAPTAIN",
        icon: (
          <FaUserTie
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "captains",
      },
      {
        label: "Kennels",
        key: "CREATE_KENNEL",
        icon: (
          <LiaWarehouseSolid
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "kennels",
      },
      {
        label: "Vaccine Certificates",
        key: "GET_UNVERIFIED_VACCINE_CERTIFICATES",
        icon: (
          <AiOutlineSafetyCertificate
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "vaccineCertificates",
      },
      // {
      //   label: "Settings",
      //   key: "SETTINGS",
      //   icon: <CustomSettingsIcon />,
      //   className: styles.icon,
      //   path: "accounts/id",
      // },
      {
        label: "Subscription Plans details",
        key: "CREATE_PLAN",
        icon: (
          <MdSubscriptions
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "plans",
      },
      // {
      //   label: "Logout",
      //   key: uuidv4(),
      //   icon: <CustomLogoutIcon />,
      //   className: styles.icon,
      // },
      {
        label: "Users",
        key: "GET_ALL_USERS",
        icon: (
          <LuUsers
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }} />
        ),
        className: styles.icon,
        path: "users",
      },
    ],
    SUPER_ADMIN: [
      {
        label: "dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "Boarding centers",
        key: "CRUD_BOARDING_CENTER",
        icon: (
          <RiHome2Fill
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "allBoardingCenters",
        children: [
          {
            key: 'CRUD_BOARDING_CENTE',
            label: 'Boarding center',
            path: "allBoardingCenters",
          },
          {
            key: 'CREATE_STATE',
            label: 'Create state',
            path: "state",
          },
          {
            key: 'CREATE_CITY',
            label: 'Create city',
            path: "city",
          },
        ],
      },
      {
        label: "HELP",
        key: "CRUD_HELP",
        icon: (
          <MdHelp
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "help",
      },
      {
        label: "Subscription Plans details",
        key: "CREATE_PLAN",
        icon: (
          <MdSubscriptions
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "plans",
      },
      {
        label: "Pending Plans",
        key: "GET_ALL_PENDING_PLANS",
        icon: (
          <MdSubscriptions
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "pendingPlans",
      },
      {
        label: "Users",
        key: "GET_ALL_USERS",
        icon: (
          <LuUsers
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }} />
        ),
        className: styles.icon,
        path: "users",
      },
      // {
      //   label: "Spa & Groomers",
      //   key: uuidv4(),
      //   icon: (
      //     <PiBathtub
      //       style={{
      //         fontSize: "30px",
      //         borderRadius: "3px",
      //         background: "white",
      //         color: "#FF7E00",
      //         padding: "5px",
      //       }}
      //     />
      //   ),
      //   className: styles.icon,
      //   path: "head/spa-groomers",
      // },
      // {
      //   label: "Service Details",
      //   key: "SERVICE_DETAILS",
      //   icon: <CustomServicesIcon />,
      //   className: styles.icon,
      //   path: "services/newAppointments",
      // },
      // {
      //   label: "Pet Trainers",
      //   key: "CRUD_TRAINERS",
      //   icon: (
      //     <RiMedalFill
      //       style={{
      //         fontSize: "30px",
      //         borderRadius: "3px",
      //         background: "white",
      //         color: "#B96435",
      //         padding: "5px",
      //       }}
      //     />
      //   ),
      //   className: styles.icon,
      //   path: "trainers",
      // },
      // {
      //   label: "Veterinarians",
      //   key: "CRUD_VET",
      //   icon: (
      //     <FaUserTie
      //       style={{
      //         fontSize: "30px",
      //         borderRadius: "3px",
      //         background: "white",
      //         color: "#B96435",
      //         padding: "5px",
      //       }}
      //     />
      //   ),
      //   className: styles.icon,
      //   path: "veterinarians",
      // },
      // {
      //   label: "Settings",
      //   key: "SETTINGS",
      //   icon: <CustomSettingsIcon />,
      //   className: styles.icon,
      //   path: "accounts/id",
      // },
      {
        label: "Logout",
        key: uuidv4(),
        icon: <CustomLogoutIcon />,
        className: styles.icon,
      },
    ],
    STATE_ADMIN: [
      // {
      //   label: "dashboard",
      //   key: "DASHBOARD",
      //   icon: <CustomDashboardIcon />,
      //   className: styles.icon,
      //   path: "",
      // },
      {
        label: "Dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "Subscription Plans details",
        key: "CREATE_PLAN",
        icon: (
          <MdSubscriptions
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "plans",
      },
      {
        label: "Create city",
        key: "CREATE_CITY",
        icon: <RiHome2Fill style={{
          fontSize: "30px",
          borderRadius: "3px",
          background: "white",
          color: "#B96435",
          padding: "5px",
        }} />,
        className: styles.icon,
        path: "city",
      },
      {
        label: "Users",
        key: "GET_ALL_USERS",
        icon: (
          <LuUsers
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }} />
        ),
        className: styles.icon,
        path: "users",
      },
    ],
    CITY_ADMIN: [
      {
        label: "dashboard",
        key: "DASHBOARD",
        icon: <CustomDashboardIcon />,
        className: styles.icon,
        path: "",
      },
      {
        label: "Subscription Plans details",
        key: "CREATE_PLAN",
        icon: (
          <MdSubscriptions
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }}
          />
        ),
        className: styles.icon,
        path: "plans",
      },
      {
        label: "Users",
        key: "GET_ALL_USERS",
        icon: (
          <LuUsers
            style={{
              fontSize: "30px",
              borderRadius: "3px",
              background: "white",
              color: "#B96435",
              padding: "5px",
            }} />
        ),
        className: styles.icon,
        path: "users",
      },
    ],
  };

  const options = (role, access) => {
    if (!access) {
      return [];
    }

    const updatedOptionMenu = optionMenu[role]?.filter((e) => {
      return e?.key && access[e.key];
    });

    return updatedOptionMenu || [];
  };

  const menuOptions = options(role, access);

  return (
    menuOptions.map(e => {
      return {
        key: e.key,
        label: e.label,
        path: e.path,
        icon: e.icon,
        className: e.className,
        children: e.children
      }
    })
  );
};

export default OptionsComponent;
