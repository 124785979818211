import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Image, Typography, theme } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import HeyhomeLogo from "../../Assets/Header/logo.png";
import styles from "../Styles/header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpen } from "../../state/actions";
import { navbarAction } from "../State/actions";
import { useRoute } from "../../context/RouteContext";
// import NotificationDrawer from "./NotificationDrawer";
// import HelpDrawer from "./HelpDrawer";
// import { helpAction } from "../../Help/State/actions";
import RightPartLargeScreenNavbar from "./RightPartLargeScreenNavbar";
const { Text } = Typography;

/**
 * A navbar component designed for large screens, displaying the logo, user address or dashboard name,
 * and options for notifications, help, and profile actions.
 *
 * @param {Object} props - The component props.
 * @param {string} props.finalAddress - The final address to be displayed in the navbar, if available.
 * @param {Array<Object>} props.profileItems - The items to be displayed in the profile dropdown menu.
 *
 * @returns {JSX.Element} A responsive navbar component tailored for large screens, with drawers for notifications and help.
 *
 * @description
 * - **Image**: Displays the Heyhome logo, which redirects to the home page on click.
 * - **address**: Displays the current address or dashboard name, with interactive elements like icons.
 * - **RightPartLargeScreenNavbar**: A child component that manages the right side of the navbar.
 * - **NotificationDrawer**: A drawer component for displaying notifications.
 * - **HelpDrawer**: A drawer component for displaying help content.
 */

const LargeScreenNavbar = ({
  finalAddress,
  profileItems,
  showDrawer,
  showDrawerHelp,
}) => {
  const navigate = useNavigate();
  const { name } = useSelector((str) => str.loadingReducer);
  const { userDetails } = useSelector((str) => str.navbarReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  // const { setSelectedKey } = useRoute();
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <>
      <Row
        gutter={16}
        style={{
          alignItems: "center",
          lineHeight: "normal",
          position: "relative",
          top: "10px",
        }}
      >
        <Col
          md={14}
          lg={16}
          xl={18}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Image
            className={styles.homelogo}
            style={{ backgroundColor: token.logoBg }}
            src={HeyhomeLogo}
            alt="Hey Home"
            preview={false}
            onClick={() => {
              navigate(`/`);
              localStorage.removeItem("bookingID");
              // setSelectedKey('DASHBOARD');
            }}
          />
          {location.pathname !== "/addAddress" && name === "" ? (
            <Typography.Paragraph
              ellipsis={{ rows: 2, symbol: "...", expandable: false }}
              onClick={() => dispatch(setDrawerOpen())}
              style={{
                paddingLeft: "25px",
                width: "400px",
                textAlign: "left",
                cursor: "pointer",
                marginBottom: "0",
              }}
            >
              <EnvironmentOutlined
                style={{ color: token.Primary, fontWeight: 500 }}
              />{" "}
              &nbsp;
              <Text className={styles.address}>
                {finalAddress
                  ? finalAddress
                  : userDetails?.usersCurrentAddressDetails?.fullAddress
                    ? userDetails?.usersCurrentAddressDetails?.fullAddress
                    : "Select location"}
              </Text>
            </Typography.Paragraph>
          ) : (
            <Text className={styles.dashboard}>{name}</Text>
          )}
        </Col>

        <RightPartLargeScreenNavbar
          showDrawerHelp={showDrawerHelp}
          showDrawer={showDrawer}
          profileItems={profileItems}
        />
      </Row>

    </>
  );
};

export default LargeScreenNavbar;
