import {
  BellOutlined,
  DownOutlined,
  GlobalOutlined,
  MoonOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SunOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Col, Dropdown, Image, Switch, Tag, theme } from "antd";
import React from "react";
import { useTheme } from "../../theme/ThemeToggle";
import LanguageItems from "./LanguageItems";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "../Styles/header.module.css";

/**
 * A component that renders the right side of the large screen navbar,
 * featuring theme toggling, language selection, search, help, notifications, and user profile options.
 *
 * @param {Object} props - The component props.
 * @param {string} props.displayName - The name of the logged-in user to display in the navbar.
 * @param {Function} props.showDrawerHelp - Function to open the help drawer.
 * @param {Function} props.showDrawer - Function to open the notifications drawer.
 * @param {Array<Object>} props.profileItems - The items to be displayed in the user profile dropdown menu.
 *
 * @returns {JSX.Element} A section of the navbar that contains various interactive icons and dropdowns.
 *
 * @description
 * - **Switch**: Toggles between light and dark themes, using a sun or moon icon.
 * - **Dropdown**: Handles the language selection and user profile menu.
 * - **SearchOutlined**: Opens the search page when clicked.
 * - **QuestionCircleOutlined**: Opens the help drawer when clicked.
 * - **BellOutlined**: Opens the notifications drawer, with a badge displaying the count of unread notifications.
 */

const RightPartLargeScreenNavbar = ({
  showDrawerHelp,
  showDrawer,
  profileItems,
}) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { toggleTheme } = useTheme();
  const languageItems = LanguageItems();
  const navigate = useNavigate();
  const { notificationCount, userDetails } = useSelector(
    (str) => str.navbarReducer
  );

  return (
    <Col
      md={10}
      lg={8}
      xl={6}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className={styles.eachitem}>
        <Switch
          size="small"
          style={{ backgroundColor: token.Gray }}
          unCheckedChildren={<MoonOutlined />}
          checkedChildren={<SunOutlined />}
          defaultChecked
          onChange={toggleTheme}
        />
      </div>
      <Dropdown menu={{ items: languageItems }}>
        <div className={styles.eachitem}>
          <GlobalOutlined />
          <DownOutlined style={{ fontSize: "10px" }} />
        </div>
      </Dropdown>
      <div className={styles.eachitem}>
        <SearchOutlined onClick={() => navigate("/search")} />
      </div>
      <div className={styles.eachitem} onClick={showDrawerHelp}>
        <QuestionCircleOutlined />
      </div>
      <Badge count={notificationCount} overflowCount={9} size="small">
        <div
          className={styles.eachitem}
          onClick={showDrawer}
          style={{ lineHeight: "normal" }}
        >
          <BellOutlined />
        </div>
      </Badge>
      <Dropdown menu={{ items: profileItems }}>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.eachitem} style={{ width: "150px" }}>
            {userDetails?.profileImage ? (
              <Avatar
                size={"small"}
                src={
                  <Image
                    src={userDetails?.profileImage}
                    alt="avatar"
                    preview={false}
                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                    fallback="https://static.vecteezy.com/system/resources/thumbnails/005/545/335/small/user-sign-icon-person-symbol-human-avatar-isolated-on-white-backogrund-vector.jpg"
                  />
                }
              />
            ) : (
              <Avatar size={"small"}>
                {userDetails?.firstName?.[0] + userDetails?.lastName?.[0] ||
                  "User"}
              </Avatar>
            )}
            &nbsp;
            <span style={{ fontSize: "18px" }}>{userDetails?.firstName}</span>
          </div>
          {userDetails?.currentRole !== "USER" ? (
            <Tag color="blue">{userDetails?.currentRole}</Tag>
          ) : (
            ""
          )}
        </div>
      </Dropdown>
    </Col>
  );
};

export default RightPartLargeScreenNavbar;
