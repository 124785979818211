import { startLoading, startLoadingActionCenter, startLoadingCaptains, stopLoading, stopLoadingActionCenter, stopLoadingCaptains } from "../../state/actions";
import { dashboardapi } from "../utils/api";
import { dashboardConstants } from "./action-types";

const getActionCenterDetailsError = (err) => {
  return {
    type: dashboardConstants.GET_ACTION_CENTER_DETAILS_FAILURE,
    error: err,
  };
};

const getActionCenterDetailsSuccess = (data) => {
  return {
    type: dashboardConstants.GET_ACTION_CENTER_DETAILS_SUCCESS,
    data: data,
  };
};

const getCaptainDetailError = (err) => {
  return {
    type: dashboardConstants.GET_CAPTAIN_DETAILS_ERROR,
    error: err,
  };
};

const getCaptainDetailSuccess = (data) => {
  return {
    type: dashboardConstants.GET_CAPTAIN_DETAILS_SUCCESS,
    data: data,
  };
};

const notifyAdminOfDecliningBookingError = (err) => {
  return {
    type: dashboardConstants.NOTIFY_ADMIN_OF_DECLINING_BOOKING_ERROR,
    error: err,
  };
};

const notifyAdminOfDecliningBookingSuccess = (data) => {
  return {
    type: dashboardConstants.NOTIFY_ADMIN_OF_DECLINING_BOOKING_SUCCESS,
    data: data,
  };
};

const getCaptainsBookingsError = (err) => {
  return {
    type: dashboardConstants.GET_CAPTAINS_BOOKINGS_ERROR,
    error: err,
  };
};

const getCaptainsBookingsSuccess = (data) => {
  return {
    type: dashboardConstants.GET_CAPTAINS_BOOKINGS_SUCCESS,
    data: data,
  };
};

const acceptOrRejectCaptainsDeclineRequestError = (err) => {
  return {
    type: dashboardConstants.ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_ERROR,
    error: err,
  };
};

const acceptOrRejectCaptainsDeclineRequestSuccess = (data) => {
  console.log(data, "sdf");
  return {
    type: dashboardConstants.ACCEPT_OR_REJECT_CAPTAINS_DECLINE_REQUEST_SUCCESS,
    data: data,
  };
};

const getActionCenterDetailsEvent = () => {
  return (dispatch) => {
    dispatch(startLoadingActionCenter());
    dashboardapi
      .actionCenterDetails()
      .then((response) => {
        dispatch(getActionCenterDetailsSuccess(response?.data?.info));
        dispatch(stopLoadingActionCenter());
      })
      .catch((err) => {
        dispatch(getActionCenterDetailsError(err));
        dispatch(stopLoadingActionCenter());
      });
  };
};

const getCaptainDetailsEvent = (page, data) => {
  return (dispatch) => {
    dispatch(startLoadingCaptains());
    dashboardapi
      .getCaptainDetails(page, data)
      .then((response) => {
        dispatch(getCaptainDetailSuccess(response?.data?.info));
        dispatch(stopLoadingCaptains());
      })
      .catch((err) => {
        dispatch(getCaptainDetailError(err));
        dispatch(stopLoadingCaptains());
      });
  };
};

const notifyAdminAboutBookingDecline = (bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    dashboardapi
      .notifyAdminOfDecliningBooking(bookingId, data)
      .then((response) => {
        dispatch(notifyAdminOfDecliningBookingSuccess(response?.data?.info));
        dispatch(getCaptainDetailsEvent(1));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(notifyAdminOfDecliningBookingError(err));
        dispatch(stopLoading());
      });
  };
};

const getCaptainsBookings = (captainId, page) => {
  return (dispatch) => {
    dispatch(startLoading());
    dashboardapi
      .getCaptainsBookings(captainId, page)
      .then((response) => {
        dispatch(getCaptainsBookingsSuccess(response?.data?.info));
        // dispatch(dashboardAction.getCaptainDetailsEvent());
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(getCaptainsBookingsError(err?.response?.data?.message));
        dispatch(stopLoading());
      });
  };
};

const acceptOrRejectCaptainsDeclineRequest = (captainId, bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    dashboardapi
      .acceptOrRejectCaptainsDeclineRequest(captainId, bookingId, data)
      .then((response) => {
        dispatch(acceptOrRejectCaptainsDeclineRequestSuccess(response?.data?.info));
        dispatch(getCaptainsBookings(captainId));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(acceptOrRejectCaptainsDeclineRequestError(err));
        dispatch(stopLoading());
      });
  };
};

const requestCancellationSuccess = (err) => {
  return {
    type: dashboardConstants.REQUEST_FOR_CANCELLATION_SUCCESS,
    error: err,
  };
};

const requestCancellationError = (data) => {
  return {
    type: dashboardConstants.REQUEST_FOR_CANCELLATION_ERROR,
    data: data,
  };
};


const requestCancellationEvent = (bookingId, data) => {
  return (dispatch) => {
    dispatch(startLoading());
    dashboardapi
      .requestCancellation(bookingId, data)
      .then((response) => {
        dispatch(requestCancellationSuccess(response?.data?.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(requestCancellationError(err));
        dispatch(stopLoading());
      });
  };
};

const clearActionCenter = () => {
  return {
      type: dashboardConstants.CLEAR_ACTION_CENTER,
  };
}


export const dashboardAction = {
  acceptOrRejectCaptainsDeclineRequest,
  getActionCenterDetailsEvent,
  getCaptainDetailsEvent,
  getCaptainsBookings,
  notifyAdminAboutBookingDecline,
  requestCancellationEvent,
  clearActionCenter
};
