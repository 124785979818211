import { message } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { signupAction } from '../State/actions';
import useWarnings from '../../Common/Components/useWarnings';

/**
 * useShowMessage is a custom hook that displays success or error messages based on the signup process.

 * @returns {Object} - An object containing `contextHolder`, which is a component to be included in the JSX to display messages.
 * @prop - props not needed
 * @description
 * - The hook listens for changes in the `signupdata` and `signupError` from the Redux store.
 * - If there is an error (`signupError`), an error message is shown.
 * - If the signup is successful (`signupdata`), a success message is shown.
 * - After displaying the message, the `clearSignupData` action is dispatched to clear the signup state.
 */

const useShowMessage = () => {
    const { signupdata, signupError } = useSelector((str) => str.signupReducer);
    // const [messageApi, contextHolder] = message.useMessage();
    const { error, success, contextHolder } = useWarnings();
    const dispatch = useDispatch();
    console.log(signupError, 'signupError')
    useEffect(() => {
        if (signupError) {
            // messageApi.open({
            //     type: "error",
            //     content: signupError,
            // });
            error(signupError);
            // captchaRef.current.reset();
        } else if (signupdata) {
            // messageApi.open({
            //     type: "success",
            //     content: signupdata,
            // });
            success(signupdata);
            // captchaRef.current.reset();
        }
        dispatch(signupAction.clearSignupData());
    }, [signupdata, signupError]);

    return {
        contextHolder
    }
}

export default useShowMessage
